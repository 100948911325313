<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem">
          <div @click="createItem" class="Button1">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Adaugă categorie
            </span>
          </div>
        </div>
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="'ACHIZITII'"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @Btn2="editItem"
          @Btn3="deleteItem"
          @totalRecords="x => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from '@/mixins/bugetarePermMixin.js'
import updateRoleStatus from '../mixins/updateUserRoleStatus.js'
import { ACHIZITII, BUGETARE, CPV_CATEGORY_PAGINATED } from '../api.js'
import DialogModal from '@/components/DialogModal'
import TableParent from '@/components/TableParent'

export default {
  name: 'Achizitii',
  $Title() {
    return `ConectX - ${this.$route.name}`
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
  },
  data() {
    return {
      tableHead: [
        {
          title: 'Categorie achiziție',
          sort: true,
          queryKey: 'achizitionCategory',
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: 'Cod CPV',
          queryKey: 'cpvCode',
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: 'Clasificație bugetare',
          queryKey: 'budgetClasification',
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: 'Data și ora creării',
          queryKey: 'createdAt',
          bottomHead: {
            quikFiltre: 'date',
          },
        },
      ],
      tableModule: ACHIZITII.get,
      totalRecords: 0,
      reloadTable: 0,
    }
  },
  methods: {
    reload() {
      this.reloadTable++
    },
    prepareData(row) {
      const preparation = [
        row.achizitionCategory || '',
        row.cpvCode?.cpvCode || '',
        row.budgetClasification?.name || '',
        this.toDateAndTime(row.createdAt) || ''
      ]

      const x = this.PERMISIONS
      const prepareBtn = []

      if (x.edit === true) {
        prepareBtn.push(2)
      }
      if (x.delete === true) {
        prepareBtn.push(3)
      }

      if (prepareBtn.length) {
        preparation.push([prepareBtn])
      }

      return preparation
    },
    checkDuplicate(cpv, budget) {
      return new Promise(r => {
        const a = Number.isInteger
        if(!a(cpv) || !a(budget)) {
          r(true)
          return
        }

        this.setSafeLoad()
        ACHIZITII.get(1, 1, `cpvCode=${cpv}&budgetClasification=${budget}`).then(res => {
          this.setLoad()
          r(res?.data?.result?.length === 0)
        }).catch(() => {
          this.setLoad()
          r(true)
        })
      })
    },
    createItem() {
      const achizitionCategory = {
        label: 'Categorie achiziție',
        id: 'achizitionCategory',
        errReason: 'Introduceți categoria de achiziție.',
      }
      const cpvCode = {
        label: 'Cod CPV',
        id: 'cpvCode',
        errReason: 'Selectați codul CPV.',
        type: "infiniteSelect",
        selectLabel: "cpvCode",
        apiOptions: {
          prepareSearch(search) {
            return [false, false, search]
          }
        },
        apiModule: CPV_CATEGORY_PAGINATED,
      }
      const budgetClasification = {
        label: 'Clasificație bugetare',
        id: 'budgetClasification',
        errReason: 'Selectați clasificația de bugetare.',
        type: "infiniteSelect",
        selectLabel: "name",
        apiModule: BUGETARE.get,
      }

      this.$modal.show(
        DialogModal,
        {
          target: 'AddAchizition',
          title: 'Adaugă categorie achiziție',
          inputs: [
            achizitionCategory,
            cpvCode,
            budgetClasification
          ],
          closeBtnText: 'Anulează',
          acceptFinish: async (sendData = {}) => {
            const checkDuplicate = await this.checkDuplicate(sendData.cpvCode, sendData.budgetClasification)
            if(!checkDuplicate) {
              this.$toastr.w('Combinația între codul cpv și clasificația bugetară deja există.')
              return false
            }
            return true
          },
          idFromObjMap: ['cpvCode', 'budgetClasification'],
          button: {
            type: 2,
            value: 'Adaugă',
            handler: sendData => {
              const error = msg => {
                this.$toastr.e(msg || 'Adăugarea categoriei de achiziție a eșuat.')
                this.reload()
                this.setLoad()
              }

              this.setSafeLoad(12000)
              ACHIZITII.add(sendData)
                .then(res => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s('Clasificația a fost adăugată.')
                    this.reload()
                    this.setLoad()
                  } else {
                    error()
                  }
                })
                .catch(error)
            },
          },
        },
        {
          name: 'AddAchizition',
          adaptive: true,
          width: '650',
          height: '800',
        }
      )
    },
    editItem(data) {
      if (!Number.isInteger(data.id)) return

      const achizitionCategory = {
        label: 'Categorie achiziție',
        id: 'achizitionCategory',
        errReason: 'Introduceți categoria de achiziție.',
      }
      const cpvCode = {
        label: 'Cod CPV',
        id: 'cpvCode',
        errReason: 'Introduceți codul CPV.',
        type: "infiniteSelect",
        selectLabel: "cpvCode",
        apiOptions: {
          prepareSearch(search) {
            return [false, false, search]
          }
        },
        apiModule: CPV_CATEGORY_PAGINATED,
      }
      const budgetClasification = {
        label: 'Clasificație bugetare',
        id: 'budgetClasification',
        errReason: 'Introduceți clasificația de bugetare.',
        type: "infiniteSelect",
        selectLabel: "name",
        apiModule: BUGETARE.get,
      }

      this.$modal.show(
        DialogModal,
        {
          target: 'EditAchizition',
          title: 'Modifică categorie achiziție',
          inputs: [
            achizitionCategory,
            cpvCode,
            budgetClasification
          ],
          initialInputsData: data,
          closeBtnText: 'Anulează',
          acceptFinish: async (sendData = {}) => {
            this.setSafeLoad()
            
            const checkCanDelete = await ACHIZITII.canDelete(data.id)
            if(checkCanDelete?.data?.result?.canDelete !== true) {
              this.$toastr.w('Categoria de achiziție nu poate fi editată.<br/>Aceasta face parte dintr-un produs.')
              this.setLoad()
              this.$modal.hide('EditAchizition')
              return false
            }

            const checkDuplicate = await this.checkDuplicate(sendData.cpvCode, sendData.budgetClasification)
            if(!checkDuplicate) {
              this.$toastr.w('Combinația între codul cpv și clasificația bugetară deja există.')
              return false
            }
            return true
          },
          idFromObjMap: ['cpvCode', 'budgetClasification'],
          button: {
            type: 2,
            value: 'Modifică',
            handler: sendData => {
              sendData = this.getEdited(sendData, data, true)

              const error = msg => {
                this.$toastr.e(msg || 'Adăugarea categoriei de achiziție a eșuat.')
                this.reload()
                this.setLoad()
              }

              this.setSafeLoad(12000)
              ACHIZITII.edit(data.id, sendData)
                .then(res => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s('Categoria a fost modificată.')
                    this.reload()
                    this.setLoad()
                  } else {
                    error()
                  }
                })
                .catch(error)
            },
          },
        },
        {
          name: 'EditAchizition',
          adaptive: true,
          width: '650',
          height: '800',
        }
      )

    },
    deleteItem(data) {
      if (!Number.isInteger(data?.id)) return

      this.$modal.show(
        DialogModal,
        {
          target: 'DeleteAchizition',
          title: 'Ștergere categorie achiziție',
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi categoria de achiziție selectată?</p></span>',
          closeBtnText: 'Anulează',
          acceptFinish: async () => {
            this.setSafeLoad()
            
            const checkCanDelete = await ACHIZITII.canDelete(data.id)
            if(checkCanDelete?.data?.result?.canDelete !== true) {
              this.$toastr.w('Categoria de achiziție nu poate fi editată.<br/>Aceasta face parte dintr-un produs.')
              this.setLoad()
              this.$modal.hide('DeleteAchizition')
              return false
            }

            return true
          },
          button: {
            type: 3,
            value: 'Șterge',
            handler: () => {
              const error = msg => {
                this.$toastr.e(msg || 'Ștergerea categoriei a eșuat.')
                this.reload()
                this.setLoad()
              }

              this.setSafeLoad(12000)
              ACHIZITII.delete(data.id)
                .then(res => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s('Categoria a fost ștearsă.')
                    this.reload()
                    this.setLoad()
                  } else {
                    error()
                  }
                })
                .catch(error)
            },
          },
        },
        {
          name: 'DeleteAchizition',
          adaptive: true,
          width: '650',
          height: '400',
        }
      )
    },
  },
  created() {
    if (!this.PERMISIONS.list) {
      return
    }

    const canViewButtonsColumn = ['edit', 'delete']

    this.updateRoleStatus()
    if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: 'clear',
        },
      })
    }
  },
}
</script>
